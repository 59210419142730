/**
 * Redirects to Intended or /forms page if the current page should be disabled or not shown in APP_MODE guest.
 */
export default defineNuxtRouteMiddleware((to, from) => {
  const redirectTo = (to?.query?.redirectTo as string) ?? "/forms";

  // Check if APP_MODE is guest
  if (isAppInGuestMode()) {
    // Then no login is required. And, page with this middleware probably should not be shown. So, Forward to app.
    return navigateTo(redirectTo, {
      replace: true,
    });
  }
});
